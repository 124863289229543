var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "breadcrumb" }, [
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "card" },
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName2,
              callback: function ($$v) {
                _vm.activeName2 = $$v
              },
              expression: "activeName2",
            },
          },
          [
            _vm.authority.tabs["ms"]
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "MS管理", name: "six" } },
                  [
                    _c("ms-control", {
                      ref: "collector",
                      attrs: { authority: _vm.authority.tabs["ms"] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }